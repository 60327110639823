import React, { useEffect, useState } from "react";
import {
  Typography,
  TextField,
  Button,
  Stepper,
  Step,
  StepLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {
  useForm,
  Controller,
  FormProvider,
  useFormContext,
} from "react-hook-form";

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));


function getSteps() {
  return [
    "Registration",
    "Verification",
  ];
}

const record = (e) => {
  document.getElementById("video").style.display = "block";
  document.getElementById("canvas").style.display = "block";

    let video = document.querySelector("#video");

    if (navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia({ video: true })
            .then(function (stream) {
                video.srcObject = stream;
                document.getElementById("record").style.display = "none";
                document.getElementById("capture").style.display = "block";

            })
            .catch(function (err0r) {
                console.log("Something went wrong!");
            });
    }


}

const capture = () => {
    var canvas = document.getElementById('canvas');
    var video = document.getElementById('video');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas.getContext('2d').drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
    canvas.toBlob(blob => {
        const img = new Image();
        img.src = window.URL.createObjectURL(blob);
    });

    // setActiveStep(1);
}

const RegistrationForm = () => {


  const { control } = useFormContext();
  return (
    <>
      <Controller
        control={control}
        name="emailAddress"
        render={({ field }) => (
          <TextField
            id="emailAddress"
            label="Email Address"
            variant="outlined"
            placeholder="Enter Your Email Address"
            fullWidth
            margin="normal"
            {...field}
          />
        )}
      />

      <Controller
        control={control}
        name="password"
        render={({ field }) => (
          <TextField
            id="password"
            label="Password"
            type="password"
            variant="outlined"
            placeholder="Enter Your Password"
            fullWidth
            margin="normal"
            {...field}
          />
        )}
      />

      <Controller
        control={control}
        name="identityNumber"
        render={({ field }) => (
          <TextField
            id="identityNumber"
            label="Identity Number"
            variant="outlined"
            placeholder="Enter Your Identity Number"
            fullWidth
            margin="normal"
            {...field}
          />
        )}
      />
    </>
  );
};

const LivenessForm = () => {
  const { control } = useFormContext();

  return (
    <>
      <p className="py-4">Start verification and stare into the soul of the camera for 5 seconds</p>
      <video id="video" style={{ display: "none", overflow: 'auto' }} autoPlay playsInline ></video>
      <canvas id="canvas" style={{ display: "none", overflow: 'auto' }}></canvas>
    </>
  );

};

function getStepContent(step) {
  switch (step) {
    case 0:
      return <RegistrationForm />;
    case 1:
      return <LivenessForm />;
    default:
      return "unknown step";
  }
}

const HotStepper = () => {
  const classes = useStyles();
  const methods = useForm({
    defaultValues: {
      emailAddress: "",
      password: "",
      identityNumber: "",
    },
  });
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  useEffect(() => {
    document.getElementById("record").style.display = "none";
    document.getElementById("capture").style.display = "none";
  }, []);

  const handleNext = (data) => {
    console.log(data);
    if (activeStep == steps.length - 1) {
      
    } else {
      setActiveStep(activeStep + 1);
      document.getElementById("next").style.display = "none";
      document.getElementById("capture").style.display = "none";
      document.getElementById("record").style.display = "block";
      
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  // const onSubmit = (data) => {
  //   console.log(data);
  // };
  return (
    <Card className="mobile-container text-center">
        <CardContent>
        <h1>Passive Onboarding</h1>
      <Stepper alternativeLabel activeStep={activeStep}>
        {steps.map((step, index) => {
          const labelProps = {};
          const stepProps = {};

          return (
            <Step {...stepProps} key={index}>
              <StepLabel {...labelProps}>{step}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {activeStep === steps.length ? (
        <Typography variant="h3" align="center">
          Success
        </Typography>
      ) : (
            <>
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(handleNext)}>
                  {getStepContent(activeStep)}

                  
                  <div className="flex gap-0.5 pt-10">
                    <Button
                      id="next"
                      className="grow"
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      // type="submit"
                    >
                      {activeStep === steps.length - 1 ? "Finish" : "Next"}
                    </Button>

                    <Button
                      variant="contained"
                      id="record"
                      className="grow"
                      onClick={record}
                    >
                      Start
                    </Button>&nbsp;
                    <Button
                      variant="contained"
                      id="capture"
                      className="grow"
                      onClick={capture}
                    >
                      Verify
                    </Button>
                  </div>
                </form>
              </FormProvider>
            </>
      )}
      </CardContent>
    </Card>
  );
};

export default HotStepper;