import { TextField, Button } from '@mui/material';

const Registration = () => {
    return (
        <div className="Form">
            <TextField id="emailAddress" label="Email Address" variant="standard" className="p-10" />
            <br/>
            <TextField id="password" label="Password" variant="standard" className="p-10" type="password" />
            <br/>
            <TextField id="identityNumber" label="Identity Number" variant="standard" className="p-10" />
        </div>
    );
  };
  
  
  export default Registration;
  