// import logo from './logo.svg';
import './App.css';
// import SearchIcon from "@mui/icons-material/Search";
import React, { useState } from "react";
import { Route, Routes } from 'react-router-dom';
import Passive from './pages/passive';
import Home from './pages/home';
import Stepper from './pages/stepper';


function App() {

return (
  <>
    <div className="container">
      <Routes>
        <Route path ="/" element={<Stepper />} />
        <Route path ="/passive" element={<Passive />} />
        <Route path ="/stepper" element={<Home />} />
      </Routes>
    </div>
  </>
)



}


const UploadAndDisplayImage = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  return (
    <div>
      <h1>Upload and Display Image usign React Hook's</h1>
      {selectedImage && (
        <div>
        <img alt="not fount" width={"250px"} src={URL.createObjectURL(selectedImage)} />
        <br />
        <button onClick={()=>setSelectedImage(null)}>Remove</button>
        </div>
      )}
      <br />
     
      <br /> 
      <input
        type="file"
        name="myImage"
        onChange={(event) => {
          console.log(event.target.files[0]);
          setSelectedImage(event.target.files[0]);
        }}
      />
    </div>
  );
};

export default App;
