import { TextField, Button } from '@mui/material';

export default function Home() {
    const record = (e) => {
        let video = document.querySelector("#video");
        
        if (navigator.mediaDevices.getUserMedia) {
          navigator.mediaDevices.getUserMedia({ video: true })
            .then(function (stream) {
              video.srcObject = stream;
            })
            .catch(function (err0r) {
              console.log("Something went wrong!");
            });
        }
      }
    
      const capture = () => {
        var canvas = document.getElementById('canvas');     
        var video = document.getElementById('video');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas.getContext('2d').drawImage(video, 0, 0, video.videoWidth, video.videoHeight);  
        canvas.toBlob(blob => {
          const img = new Image();
          img.src = window.URL.createObjectURL(blob);
        });
      } 
    
    
    
      return (
        <div className="App">
          <header className="App-header">
            <h1 className="">
              Liveness Onboarding
            </h1>
          </header>
          <div>
          {/* <SearchIcon /> */}
    
            <div className="Form">
              <form>
                <TextField id="emailAddress" label="Email Address" variant="standard" className="p-10" />
                <br/>
                <TextField id="password" label="Password" variant="standard" className="p-10" type="password" />
                <br/>
                <TextField id="identityNumber" label="Identity Number" variant="standard" className="p-10" />
    
                <div className="text-center">
                  <p className="p-10">Start verification and stare into the soul of the camera for 5 seconds</p>
    
                  <Button 
                    variant="contained" 
                    id="record"
                    onClick={record}
                  > 
                    Start Verification
                  </Button>
    
                  <div className="clearfix">&nbsp;</div>
                  <div className="p-10">
                    <Button 
                      variant="contained" 
                      id="capture"
                      onClick={capture}
                    > 
                      Capture
                    </Button>
                  </div>
    
                  <div className="clearfix"><br /></div>
    
                  <video id="video" autoPlay playsInline ></video>
                  <canvas id="canvas" style={{overflow: 'auto'}}></canvas>
                  
                </div>
              </form>
            </div>        
          </div>
        </div>
      );
}