import { Button } from '@mui/material';

const Selfie = () => {

    const record = (e) => {
        let video = document.querySelector("#video");

        if (navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({ video: true })
                .then(function (stream) {
                    video.srcObject = stream;
                })
                .catch(function (err0r) {
                    console.log("Something went wrong!");
                });
        }
    }

    const capture = () => {
        var canvas = document.getElementById('canvas');
        var video = document.getElementById('video');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas.getContext('2d').drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        canvas.toBlob(blob => {
            const img = new Image();
            img.src = window.URL.createObjectURL(blob);
        });
    }

    return (
        <>
            <p className="py-4">Start verification and stare into the soul of the camera for 5 seconds</p>

            <Button
                variant="contained"
                id="record"
                onClick={record}
            >
                Start
            </Button>&nbsp;
            <Button
                variant="contained"
                id="capture"
                onClick={capture}
            >
                Verify
            </Button>

            <div className="clearfix"><br /></div>

            <video id="video" autoPlay playsInline ></video>
            <canvas id="canvas" ></canvas>
        </>
    );
};


export default Selfie;
